import React, { useEffect, useState } from "react";
import { Button, Col, Form, ProgressBar, Row } from "react-bootstrap";
import Select from "react-select";
import {
  _l,
  countryCodedigitLength,
  csvOnLoadHandler,
  searchObjects,
  showError,
  validateEmail,
} from "../../../hooks/utilities";
import { COUNTRY_CODE_OPTIONS } from "../../../constants/constants";
import BenchmarkServices from "../../../services/benchmark-services";
import { Spinner } from "reactstrap";
import CustomerServices from "../../../services/customer-services";
import ImportProviders from "../../Modals/ImportProviders";
import { useNavigate, useSearchParams } from "react-router-dom";
import CommanPlaceholder from "../../Placeholder/CommanPlaceholder";
import { Trash } from "phosphor-react";
import { useDispatch, useSelector } from "react-redux";
import { showConfirmation, toastCloseAction } from "../../../actions/messages";
import AuthServices from "../../../services/auth-services";
import CommanSearchbox from "../../Searchbox/CommanSearchbox";
import PhoneInput from "react-phone-input-2";

const SelectProvider = ({
  providerData,
  setProviderData,
  providerList,
  invitedProviders,
  setInvitedProviders,
  setStep,
}) => {
  const selectedOffice = localStorage.getItem("selectedOffice");

  const dispatch = useDispatch();

  const [newProviderDetails, setNewProviderDetails] = useState({
    name: "",
    email: "",
    countryCode: "+33",
    phonenumber: "",
    companyName: "",
  });
  const [bulkUploads, setBulkUploads] = useState(false);
  const [inviteSendLoader, setInviteSendLoader] = useState(false);
  const [csvFile, setCsvFile] = useState("");
  const [processedCSV, setProcessedCSV] = useState("");
  const [bulkImportLoader, setBulkImportLoader] = useState(false);

  const userType = localStorage.getItem("user_type");
  const applyFullwidthClass = providerList && providerList.length || invitedProviders.length;
  const [searchString, setSearchString] = useState("");
  const [numberFormate, setNumberFormate] = useState('');
  const [numberlength, setNumberLength]= useState({max : 14, min : 9});
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { benchmarkDetail} = useSelector(
    (state) => state.customer
  );

  const emailCheck = async(email) => {
    if (validateEmail(email)) {
      try {
        const response = await CustomerServices.emailCheckInvitees(
          email,
          providerData.categoryId,
          selectedOffice,
          userType === "operator"
        );
        if (response.status) {
          let countryCode = '+33' ;
          let phoneNumbers =  '';
          if (response.data.phonenumber && response.data.phonenumber.includes("+")) {
            countryCode = response.data.phonenumber.match(/^\+(\d+)/)[0]
            phoneNumbers = response.data.phonenumber.match(/\d+/g).join('');
          }
          let maxLength = countryCodedigitLength(countryCode)
          setNumberLength(maxLength);
          setNewProviderDetails({
            email,
            companyName: response.data.company,
            countryCode : countryCode,
            phonenumber: phoneNumbers,
            name: response.data.fullname,
            clientId: response.data.userid,
          });
        }
      }
      catch(e)
      {
        console.error(e);
        showError("l_something_went_wrong");
      }
    }
  };

  const updateFormData = (e) => {
    let field = e.target.name;
    let value = e.target.value;
    switch (field) {
      case "email":
        emailCheck(value);
        break;
      case "name":
        value = value.replace(/[^a-zA-Z\s]/g, "").substring(0, 50);
        break;
      case "companyName":
        value = value.substring(0, 30);
        break;
      case "phonenumber":
        value = value.replace(/\D/g, "").substring(0, 10);
        break;
      default:
        break;
    }
    setNewProviderDetails({ ...newProviderDetails, [field]: value });
  };

  const validateProviderInputs = () => {
    let validInputs = true;
    if (!newProviderDetails.name || !newProviderDetails.name.trim().length) {
      showError("l_please_enter_name");
      validInputs = false;
    } else if (
      !newProviderDetails.email ||
      !validateEmail(newProviderDetails.email)
    ) {
      showError("l_please_enter_valid_email");
      validInputs = false;
    } else if (!newProviderDetails.countryCode) {
      showError("l_please_select_the_country_code");
      validInputs = false;
    } else if (
      !newProviderDetails.phonenumber || (numberlength.max != newProviderDetails.phonenumber.length && numberlength.min != newProviderDetails.phonenumber.length)
    ) {
      showError("l_please_enter_valid_mobile_number");
      validInputs = false;
    }
    return validInputs;
  };

  const inviteProvider = async () => {
    if (validateProviderInputs()) {
      if (!bulkUploads) {
        setInviteSendLoader(true);
        if (userType !== "operator") {
          BenchmarkServices.addBulkProvidersToCategory(
            selectedOffice,
            providerData.categoryId,
            [
              {
                email: newProviderDetails.email,
                name: newProviderDetails.name,
                phonenumber: newProviderDetails.phonenumber && newProviderDetails.phonenumber.length ? `${newProviderDetails.countryCode} ${newProviderDetails.phonenumber.slice(newProviderDetails.countryCode.length - 1)}` : "",
                company_name: newProviderDetails.companyName,
              },
            ]
          )
            .then((response) => {
              if (response.status && response.data && response.data.length) {
                let newProviders = [];
                setNewProviderDetails({
                  countryCode: "",
                  email: "",
                  name: "",
                  phonenumber: "",
                  companyName: "",
                });
                response.data.map((x) => {
                  if (
                    providerList.findIndex(
                      (w) =>
                        w.email.trim().toLowerCase() ===
                        x.email.trim().toLowerCase()
                    ) === -1
                  ) {
                    newProviders.push({
                      staffid: x.staffid,
                      email: x.email,
                      profile_image_url: x.profile_image_url,
                      fullname: x.fullname,
                      company: x.company,
                    });
                  }
                });
                setInvitedProviders(invitedProviders.concat(newProviders));
              } else {
                showError("l_something_went_wrong");
                setInviteSendLoader(false);
              }
            })
            .catch(() => {
              showError("l_something_went_wrong");
              setInviteSendLoader(false);
            })
            .finally(() => {
              setInviteSendLoader(false);
            });
        } else {
          if (
            providerData.invited_customers.find(
              (w) =>
                w.email.trim().toLowerCase() ==
                newProviderDetails.email.trim().toLowerCase()
            )
          ) {
            showError("l_provider_already_added");
            return false;
          }
          let customerDetails = [
            {
              name: newProviderDetails.name,
              email: newProviderDetails.email,
              phonenumber: newProviderDetails.phonenumber && newProviderDetails.phonenumber.length ?  `${newProviderDetails.countryCode} ${newProviderDetails.phonenumber.slice(newProviderDetails.countryCode.length - 1)}` : "",
            },
          ];
          CustomerServices.inviteCustomerToSpace(
            newProviderDetails.companyName,
            customerDetails,
            selectedOffice
          )
            .then((response) => {
              if (response.status && response.data && response.data.length) {
                setNewProviderDetails({
                  ...newProviderDetails,
                  name: "",
                  email: "",
                  countryCode: "+33",
                  phonenumber: "",
                  companyName: "",
                });
                if (providerData.type == "invite_provider") {
                  AuthServices.acceptProviderInvite({
                    email: newProviderDetails.email,
                    phonenumber: newProviderDetails.phonenumber && newProviderDetails.phonenumber.length ? `${newProviderDetails.countryCode} ${newProviderDetails.phonenumber.slice(newProviderDetails.countryCode.length - 1)}` : "",
                    company: newProviderDetails.companyName,
                    project_id: selectedOffice,
                  });
                  navigate(`/explore-features?space=${selectedOffice}`);
                } else {
                  setProviderData({
                    ...providerData,
                    invited_customers: providerData.invited_customers.concat({
                      ...customerDetails[0],
                      id: response.data[0].invitee_id,
                      profile_image_url: response.data[0].profile_image_url,
                      fullname: customerDetails[0].name,
                    }),
                  });
                }
              }
            })
            .catch((e) => {
              showError("l_something_went_wrong");
              console.error(e);
            })
            .finally(() => {
              setInviteSendLoader(false);
            });
        }
      }
    }
  };

  const addEditInvitedProviders = (staffid, email, check) => {
    if (check) {
      if (
        providerData.invited_providers.findIndex(
          (x) =>
            x.email.trim().toLowerCase() == email.trim().toLowerCase() &&
            x.staffid == staffid
        ) === -1
      ) {
        setProviderData({
          ...providerData,
          invited_providers: providerData.invited_providers.concat({
            staffid,
            email,
          }),
        });
      }
    } else {
      setProviderData({
        ...providerData,
        invited_providers: providerData.invited_providers.filter(
          (x) =>
            x.email.trim().toLowerCase() != email.trim().toLowerCase() &&
            x.staffid != staffid
        ),
      });
    }
  };

  const handleCsvImport = async () => {
    setBulkImportLoader(true);
    setBulkUploads(false);
    setCsvFile("");
    let providerArray = [];

    try {
      if (processedCSV) {
        const rows = processedCSV.split("\n");

        const spreadsheetData = rows.map((row) => {
          const columns = row.split(",");
          return columns.map((column) => column.replace(/(^"|"$)/g, ""));
        });

        spreadsheetData.forEach((row, rowIndex) => {
          if (rowIndex != 0) {
            let email = row[0] && validateEmail(row[0]) ? row[0] : "";
            let name =
              row[1] && row[1].trim().length
                ? row[1].replace(/[^a-zA-Z\s]/g, "").substring(0, 50)
                : "";
            let countryCode =
              row[2] && row[2].trim().length
                ? row[2]
                    .trim()
                    .replace(/[- .a-zA-Z]/g, "")
                    .substring(0, 3)
                : "";
            let phonenumber =
              row[2] && row[2].trim().length
                ? row[2]
                    .trim()
                    .replace(/[- .a-zA-Z]/g, "")
                    .substring(3)
                : "";
            if (email && name && countryCode && phonenumber) {
              providerArray.push({
                email,
                name,
                phonenumber: phonenumber && phonenumber.length ? `${countryCode}${phonenumber}`  : "",
                company_name: newProviderDetails.companyName,
              });
            }
          }
        });

        if (providerArray && providerArray.length) {
          if (userType === "operator") {
            if (!newProviderDetails.companyName) {
              showError("l_please_enter_company_name");
              return false;
            }
            providerArray = providerArray.filter(
              (w) =>
                !providerData.invited_customers.find(
                  (x) =>
                    x.email.trim().toLowerCase() == w.email.trim().toLowerCase()
                )
            );
            if (!providerArray.length) {
              showError("l_no_customers_or_already_invited");
              return false;
            }
            CustomerServices.inviteCustomerToSpace(
              newProviderDetails.companyName,
              providerArray,
              selectedOffice
            )
              .then((response) => {
                if (response.status && response.data && response.data.length) {
                  providerArray.forEach((customer) => {
                    const record = response.data.find(
                      (w) =>
                        w.invitee_email.trim().toLowerCase() ==
                        customer.email.trim().toLowerCase()
                    );
                    if (record) {
                      customer.id = record.invitee_id;
                      customer.fullname = customer.name;
                      customer.profile_image_url = record.profile_image_url;
                    }
                  });
                  setProviderData({
                    ...providerData,
                    invited_customers:
                      providerData.invited_customers.concat(providerArray),
                  });
                } else {
                  showError("l_something_went_wrong");
                }
              })
              .catch(() => {
                showError("l_something_went_wrong");
              })
              .finally(() => {
                setInviteSendLoader(false);
              });
          } else {
            BenchmarkServices.addBulkProvidersToCategory(
              selectedOffice,
              providerData.categoryId,
              providerArray
            )
              .then((response) => {
                if (response.status && response.data && response.data.length) {
                  let newProviders = [];
                  response.data.map((x) => {
                    if (
                      providerList.findIndex(
                        (w) =>
                          w.email.trim().toLowerCase() ===
                          x.email.trim().toLowerCase()
                      ) === -1
                    ) {
                      newProviders.push({
                        staffid: x.staffid,
                        email: x.email,
                        profile_image_url: x.profile_image_url,
                        fullname: x.fullname,
                        company_name: x.company_name,
                      });
                    }
                  });
                  setInvitedProviders(invitedProviders.concat(newProviders));
                } else {
                  showError("l_something_went_wrong");
                }
              })
              .catch(() => {
                showError("l_something_went_wrong");
              })
              .finally(() => {
                setInviteSendLoader(false);
              });
          }
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setBulkImportLoader(false);
      setBulkUploads(false);
    }
  };

  const deleteInvitee = (inviteeId) => {
    dispatch(
      showConfirmation(
        _l("l_are_you_sure"),
        _l("l_cancel_customer_invite"),
        _l("l_delete"),
        _l("l_cancel"),
        () => {},
        () => {
          BenchmarkServices.deleteCustomerInvitee(selectedOffice, inviteeId)
            .then((res) => {
              if (res.status) {
                setProviderData({
                  ...providerData,
                  invited_customers: providerData.invited_customers.filter(
                    (w) => w.invitee_id != inviteeId
                  ),
                });
              } else {
                showError(res.message);
              }
            })
            .catch((e) => {
              console.error(e);
              showError("l_something_went_wrong");
            })
            .finally(() => {
              dispatch(toastCloseAction());
            });
        }
      )
    );
  };

  const viewProviderProfile = (userId) => {
    navigate(
      `/providers?space=${selectedOffice}&actionType=add-contact&invitee=provider&provider=${userId}&category=${
        providerData.categoryId
      }&categoryName=${encodeURIComponent(providerData.categoryName)}`
    );
  };

  const selectBulkMyrNetworkProviders = (count) => {
    let allMyrProviders = providerData.total_myr_providers_data;
    let currentInvitedProviders = providerData.invited_providers.filter(
      (w) =>
        !allMyrProviders.find(
          (z) => z.email == w.email && w.staffid == z.staffid
        )
    );
    currentInvitedProviders = currentInvitedProviders.concat(
      allMyrProviders.slice(0, count)
    );
    setProviderData({
      ...providerData,
      invited_providers: currentInvitedProviders,
    });
  };

  const areProvidersAvailableForCategory = () => {
    let flag = true;
    if(!providerData.sources.includes("invite_provider") && !invitedProviders.length && (providerList && !providerList.length))
    {
      flag = false;
    }
    return flag;
  };

  useEffect(() => {
    if (providerList && providerList.length) {
      setInvitedProviders(providerList.filter((x) => x.is_existing == 0));
    }
  }, [providerList]);

  useEffect(() => {
    let providerEmail = searchParams.get("email");
    if (providerEmail && providerEmail.trim().length) {
      providerEmail = decodeURIComponent(providerEmail);
      updateFormData({ target: { name: "email", value: providerEmail } });
    }
    if(!areProvidersAvailableForCategory())
    {
      showError("l_no_providers_in_previous_list");
    }
  }, []);

  useEffect(() => {
    if (numberFormate.dialCode) {
        setNewProviderDetails({
          ...newProviderDetails,
          countryCode: `+${numberFormate.dialCode}`,
        });
        const dialcodeL = Number(numberFormate.dialCode.length);
        let digitlength = numberFormate.format.match(/\./g).slice(numberFormate.dialCode.length);
        if (["32","352", "49", "44"].includes(numberFormate.dialCode)) {
            if (["49", "44"].includes(numberFormate.dialCode)) {
                setNumberLength({max : 11 + dialcodeL, min : 10 + dialcodeL})
            }else{
                setNumberLength({max : 10 + dialcodeL, min : 9 + dialcodeL})
            }
        }else{
            setNumberLength({max : digitlength.length + dialcodeL, min : digitlength.length + dialcodeL})
        }
    }
  }, [numberFormate])

  return (
    <Row className="flex-grow-1 justify-content-center h-100">
      {providerData.sources.includes("invite_provider") ||
      userType === "operator" ||
      providerData.type === "invite_provider" || !areProvidersAvailableForCategory() ? (
        <Col
          className="overflow-hiiden-auto h-100 padding-bottom-60px"
          xs={applyFullwidthClass ? 4 : 7}
        >
          <Form.Label className="c-font f-14 title-fonts mb-3">
            {userType === "operator"
              ? `${_l("l_invite_customer")}`
              : `${_l("l_invite_provider")} - ${providerData.categoryName}`}
          </Form.Label>
          <div className="row">
            <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
              <Form.Label className="input-label no-asterisk no-asterisk">
                {userType !== "operator"
                  ? `${_l("l_provider_email")}`
                  : `${_l("l_customer")} ${_l("l_email")}`}
              </Form.Label>
              <input
                className="form-control p-15"
                placeholder={_l("l_email_placeholder")}
                type="email"
                name="email"
                value={newProviderDetails.email}
                onChange={updateFormData}
              />
            </Form.Group>
            <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
              <Form.Label className="input-label no-asterisk no-asterisk">
                {userType !== "operator"
                  ? `${_l("l_provdier_name")}`
                  : `${_l("l_customer")} ${_l("l_name")}`}
              </Form.Label>
              <input
                className="form-control p-15"
                placeholder="John"
                type="text"
                name="name"
                onChange={updateFormData}
                value={newProviderDetails.name}
              />
            </Form.Group>
            <Form.Group
              className={`col-xl-${
                applyFullwidthClass ? "12" : "6"
              } c-input-box pb-3 position-relative`}
            >
              <Form.Label className="input-label no-asterisk no-asterisk">
                {_l("l_company_name")}
              </Form.Label>
              <input
                className="form-control p-15"
                placeholder={_l("l_company_name")}
                type="text"
                name="companyName"
                value={newProviderDetails.companyName}
                onChange={updateFormData}
              />
            </Form.Group>
            <Form.Group
              className={`col-xl-${
                applyFullwidthClass ? "12" : "6"
              } c-input-box pb-3 position-relative select-half-separator`}
            >
              <Form.Label className="input-label no-asterisk no-asterisk">
                {_l("l_mobile_number")}
              </Form.Label>
              <div className="align-items-center d-flex m-0 react-tel-input-small row select-half-separator-main">
                <PhoneInput
                className="p-10"
                    autoFormat={true}
                    enableLongNumbers = {true}
                    countryCodeEditable={false}
                    disableCountryGuess={true}
                    searchStyle={{"border-color" : "red"}}
                    disableSearchIcon={true}
                    enableSearch={true}
                    localization={""}
                    country='fr'
                    preferredCountries={['fr', 'de', 'be', 'es', 'ch','gb','pl','lu']}
                    name="mobile_number"
                    value={newProviderDetails.phonenumber}
                    onChange={(phone, formattedValue) => {
                      setNewProviderDetails({
                        ...newProviderDetails,
                        phonenumber: phone,
                      });
                      setNumberFormate(formattedValue);
                    }}
                    isValid={(value) => {
                        if (value.length > numberlength.max) {
                          setNewProviderDetails({
                            ...newProviderDetails,
                            phonenumber: value.slice(0,numberlength.max),
                          });
                        } else {
                            return true;
                        }
                    }}
                />
            </div>
              {/* <div className="row select-half-separator-main m-0">
                <div className="c_input_border drop_image col-4 col-md-4  col-xl-4 position-relative pe-0">
                  <Select
                    className="custom-select-menu scroll-height-30vh"
                    classNamePrefix="react-select"
                    placeholder={`${_l("l_country_code_placeholder")}`}
                    options={COUNTRY_CODE_OPTIONS}
                    value={COUNTRY_CODE_OPTIONS.find(
                      (x) => x.label == newProviderDetails.countryCode
                    )}
                    onChange={(e) => {
                      setNewProviderDetails({
                        ...newProviderDetails,
                        countryCode: e.label,
                      });
                    }}
                  />
                </div>
                <div className="col-8 col-md-8 col-xl-8 ps-md-0 pe-0 ">
                  <Form.Control
                    className="p-15"
                    placeholder={_l("l_mobile_number_placeholder")}
                    type="text"
                    name="phonenumber"
                    value={newProviderDetails.phonenumber}
                    onChange={updateFormData}
                  />
                </div>
              </div> */}
            </Form.Group>
          </div>
          <div
            className={`d-flex flex-wrap align-items-center ${
              applyFullwidthClass
                ? "d-grid gap-2 btn-inner-w-100"
                : " justify-content-between"
            }`}
          >
            <Button
              className=" border-0"
              disabled={inviteSendLoader}
              variant="white-05"
              onClick={inviteProvider}
            >
              {userType != "operator"
                ? `${_l("l_add_provider")}`
                : `${_l("l_invite_customer")}`}
              {inviteSendLoader ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2 `}
                  aria-hidden="true"
                />
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </Button>

            <Button
              disabled={bulkImportLoader}
              className="light-theme-border light-theme-white-bg border"
              variant="secondary"
              onClick={() => {
                if (
                  (userType === "operator" &&
                    !newProviderDetails.companyName) ||
                  !newProviderDetails.companyName.trim().length
                ) {
                  showError("l_please_enter_company_name");
                } else {
                  setBulkUploads(true);
                }
              }}
            >
              {_l("l_bulk_upload")}
              {bulkImportLoader ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2`}
                  aria-hidden="true"
                />
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </Button>
          </div>
        </Col>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {(providerList && providerList.length || invitedProviders.length) &&
      (providerData.sources.includes("invite_provider") ||
        providerData.sources.includes("choose_from_past") ||
        providerData.sources.includes("myr_network") ||
        userType === "operator" ||
        providerData.invited_customers.length) ? (
        <Col className="d-flex flex-column flex-grow-1 h-100" xs={8}>
          <Form.Label className="c-font f-14 title-fonts">
            <div className="d-flex align-items-center justify-content-between">
              <span>
                {userType == "operator"
                  ? `${_l("l_customer")} ${_l("l_contacts")}`
                  : _l("l_select_provider")}
              </span>
              <CommanSearchbox
                onChangeHandler={setSearchString}
                searchString={searchString}
              />
            </div>
          </Form.Label>
          <div className="border-dashed-2px radius_3 flex-grow-1 overflow-auto  padding-bottom-60px">
          {invitedProviders.filter(
              (w) =>
                (searchString && searchObjects(w, searchString)) ||
                !searchString
            ).length && (providerData.id == 0 || (benchmarkDetail && benchmarkDetail.is_copy_from_schedule == 1)) ? (
              <React.Fragment>
                <div className="px-2 dealing-list">
                  <div className="bg-white-03-solid c-font color-white-60 f-12 position-sticky py-2 title-fonts top-0 z-index-2">
                    #{_l("l_invitee")}
                  </div>
                  {invitedProviders
                    .filter(
                      (w) =>
                        (searchString && searchObjects(w, searchString)) ||
                        !searchString
                    ).sort(
                      (a, b) =>
                        providerData.invited_providers.some(
                          (x) => x.email === b.email && x.staffid === b.staffid
                        ) -
                        providerData.invited_providers.some(
                          (x) => x.email === a.email && x.staffid === a.staffid
                        )
                    )
                    .map((provider, index) => {
                      return (
                        <a href="#/"
                          key={index}
                          className={`d-flex align-items-center comman-list with-after-50 py-2 m-0 dealing-list-item radius_3 tab-active`}
                        >
                          <div
                            className={`d-flex align-items-center align-items-center flex-grow-1 w100minus100per`}
                          >
                            <div className="d-flex align-items-center">
                              <div
                                className={` d-flex align-items-center justify-content-center pe-3`}
                              >
                                <Form.Check
                                  className="h20w20"
                                  name="provider"
                                  disabled={providerData.id > 0 && benchmarkDetail && benchmarkDetail.is_copy_from_schedule < 0}
                                  type={
                                    providerData.type == "schedule"
                                      ? "radio"
                                      : "checkbox"
                                  }
                                  checked={
                                    providerData.invited_providers.find(
                                      (x) =>
                                        x.email == provider.email &&
                                        x.staffid == provider.staffid
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    addEditInvitedProviders(
                                      provider.staffid,
                                      provider.email,
                                      e.target.checked
                                    );
                                  }}
                                />
                              </div>
                              <div className="h25w25 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-1">
                                <div
                                  className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                  style={{
                                    backgroundImage: `url("${provider.profile_image_url}")`,
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div className="c-font color-white-80 f-14 ps-2 text-truncate title-fonts">
                              {provider.company}
                            </div>
                          </div>
                          <div className="c-font color-white-60 f-14 text-truncate flex-grow-1 w100minus100per d-none">
                            {provider.email}
                          </div>
                        </a>
                      );
                    })}
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            {providerData.sources.includes("myr_network") &&
            providerData.total_myr_providers_data &&
            providerData.total_myr_providers_data.filter(
              (w) =>
                (searchString && searchObjects(w, searchString)) ||
                !searchString
            ).length ? (
              <React.Fragment>
                <div className="px-2 dealing-list">
                  <div className="c-font color-white-60 f-12 position-sticky py-2 ps-1 title-fonts top-0 z-index-2 bg-white-03-solid-imp">
                    <div className="d-flex justify-content-between">
                      <span>Myr.ai {_l("l_myr_providers")}</span>
                      <div className="d-flex">
                        <Form.Group className="c-input-box position-relative">
                          <div className="form-check p-0 d-flex align-items-center">
                            <Form.Label className="form-check-label m-0">
                              <Form.Check
                                className="m-0"
                                inline
                                name="provider-filter"
                                label={_l("l_top_5")}
                                value={"top-5"}
                                defaultChecked
                                type="radio"
                                onChange={() =>
                                  selectBulkMyrNetworkProviders(5)
                                }
                              />
                            </Form.Label>
                            <Form.Label className="form-check-label mb-0">
                              <Form.Check
                                inline
                                name="provider-filter"
                                label={_l("l_all")}
                                type="radio"
                                value={"all"}
                                onChange={() =>
                                  selectBulkMyrNetworkProviders(
                                    providerData.total_myr_providers_data.length
                                  )
                                }
                              />
                            </Form.Label>
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                  {providerData.total_myr_providers_data
                    .filter(
                      (w) =>
                        (searchString && searchObjects(w, searchString)) ||
                        !searchString
                    )
                    .sort(
                      (a, b) =>
                        providerData.invited_providers.some(
                          (x) => x.email === b.email && x.staffid === b.staffid
                        ) -
                        providerData.invited_providers.some(
                          (x) => x.email === a.email && x.staffid === a.staffid
                        )
                    )
                    .map((provider, index) => {
                      return (
                        <a href="#/"
                          key={index}
                          className={`d-flex align-items-center comman-list with-after-50 py-2 m-0 dealing-list-item radius_3 tab-active`}
                        >
                          <div
                            className={`d-flex align-items-center align-items-center flex-grow-1 w100minus100per`}
                          >
                            <div className="d-flex align-items-center">
                              <div
                                className={` d-flex align-items-center justify-content-center pe-3`}
                              >
                                <Form.Check
                                  className="h20w20"
                                  name="provider"
                                  disabled={providerData.id > 0 && benchmarkDetail && benchmarkDetail.is_copy_from_schedule < 0}
                                  type={
                                    providerData.type == "schedule"
                                      ? "radio"
                                      : "checkbox"
                                  }
                                  checked={
                                    providerData.invited_providers.find(
                                      (x) =>
                                        x.email == provider.email &&
                                        x.staffid == provider.staffid
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    addEditInvitedProviders(
                                      provider.staffid,
                                      provider.email,
                                      e.target.checked
                                    );
                                  }}
                                />
                              </div>
                              <div className="h25w25 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-1">
                                <div
                                  className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                  style={{
                                    backgroundImage: `url("${provider.profile_image_url}")`,
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div className="c-font color-white-60 f-14 ps-2 text-truncate title-fonts">
                              {provider.company}
                            </div>
                          </div>
                         
                          <div className="c-font f-14 text-truncate flex-grow-1 w100minus100per">
                            <span className="pe-2 color-white-60">
                              {_l("l_total_task")}
                            </span>
                            :
                            <span className="ps-2">
                              {Number(provider.total_task)}
                            </span>
                          </div>
                          <div className="c-font color-white-60 f-14 text-truncate flex-grow-1 w100minus100per progress-slider">
                            <div className="d-flex align-items-center">
                              <ProgressBar
                                now={Number(provider.score)}
                                className="flex-grow-1 me-3"
                              />
                              {Number(provider.score)}%
                            </div>
                          </div>
                        </a>
                      );
                    })}
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            {providerData.invited_customers.filter(
              (w) =>
                (searchString && searchObjects(w, searchString)) ||
                !searchString
            ).length ? (
              <React.Fragment>
                <div className="px-2 dealing-list">
                  <div className="bg-white-03-solid-imp c-font color-white-60 comman-white-box f-12 position-sticky py-2 title-fonts top-0 z-index-2">
                    #{providerData.categoryName}
                  </div>
                  {invitedProviders.map((provider, index) => {
                    return (
                      <a
                        key={index}
                        className={`d-flex align-items-center comman-list with-after-50 py-2 m-0 dealing-list-item radius_3 tab-active`}
                      >
                        <div
                          className={`d-flex align-items-center align-items-center flex-grow-1 w100minus100per`}
                        >
                          <div className="d-flex align-items-center">
                            <div
                              className={` d-flex align-items-center justify-content-center pe-3`}
                            >
                              <Form.Check
                                className="h20w20"
                                name="provider"
                                disabled={providerData.id > 0 && benchmarkDetail && benchmarkDetail.is_copy_from_schedule < 0}
                                type={
                                  providerData.type == "schedule"
                                    ? "radio"
                                    : "checkbox"
                                }
                                checked={
                                  providerData.invited_providers.find(
                                    (x) =>
                                      x.email == provider.email &&
                                      x.staffid == provider.staffid
                                  )
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  addEditInvitedProviders(
                                    provider.staffid,
                                    provider.email,
                                    e.target.checked
                                  );
                                }}
                              />
                            </div>
                            <div className="h25w25 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-1">
                              <div
                                className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                style={{
                                  backgroundImage: `url("${provider.profile_image_url}")`,
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="c-font color-white-80 f-14 ps-2 text-truncate title-fonts">
                            {provider.company}
                          </div>
                        </div>
                        <div className="c-font color-white-60 f-14 text-truncate flex-grow-1 w100minus100per d-none">
                          {provider.email}
                        </div>
                      </a>
                    );
                  })}
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            {providerData.invited_customers.length ? (
              <React.Fragment>
                <div className="px-2 dealing-list">
                  <div className="bg-white-03-solid-imp c-font color-white-60 comman-white-box f-12 position-sticky py-2 title-fonts top-0 z-index-2">
                    #{`${_l("l_customer")} ${_l("l_contacts")}`}
                  </div>
                  {providerData.invited_customers
                    .filter(
                      (w) =>
                        (searchString && searchObjects(w, searchString)) ||
                        !searchString
                    )
                    .map((provider, index) => {
                      return (
                        <a href="#/"
                          key={index}
                          className={`d-flex align-items-center comman-list with-after-50 py-2 m-0 dealing-list-item radius_3 tab-active`}
                        >
                          <div
                            className={`d-flex align-items-center align-items-center flex-grow-1 w100minus100per`}
                          >
                            <div className="d-flex align-items-center">
                              <div
                                className={` d-flex align-items-center justify-content-center pe-3`}
                              ></div>
                              <div className="h25w25 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-1">
                                <div
                                  className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                  style={{
                                    backgroundImage: `url("${provider.profile_image_url}")`,
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div className="c-font color-white-80 f-14 ps-2 text-truncate title-fonts">
                              {provider.fullname}
                            </div>
                          </div>
                          <div className="c-font color-white-60 f-14 text-truncate flex-grow-1 w100minus100per">
                            {provider.email}
                          </div>
                          <div className="c-font color-white-60 f-14 text-truncate">
                            <a href="#/" onClick={() => deleteInvitee(provider.id)}>
                              <Trash
                                size={16}
                                weight="light"
                                className="c-icons"
                              />
                            </a>
                          </div>
                        </a>
                      );
                    })}
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            {(providerData.sources.includes("choose_from_past") ||
              providerData.type == "invite_provider") &&
            (providerData.id == 0 || (benchmarkDetail && benchmarkDetail.is_copy_from_schedule == 1)) &&
            providerList.filter(
              (w) =>
                !w.from_myr_network &&
                ((searchString && searchObjects(w, searchString)) ||
                !searchString)
            ).length ? (
              <React.Fragment>
                <div className="px-2 dealing-list">
                  <div className="bg-white-03-solid-imp c-font color-white-60 comman-white-box f-12 position-sticky py-2 title-fonts top-0 z-index-2">
                    {_l("l_existing")}
                  </div>
                  {providerList
                    .filter(
                      (w) =>
                        (((searchString && searchObjects(w, searchString)) ||
                        !searchString) && !w.from_myr_network)
                    ).sort(
                      (a, b) =>
                        providerData.invited_providers.some(
                          (x) => x.email === b.email && x.staffid === b.staffid
                        ) -
                        providerData.invited_providers.some(
                          (x) => x.email === a.email && x.staffid === a.staffid
                        )
                    )
                    .map((provider, index) => {
                      if (provider.is_existing == 0) {
                        return <React.Fragment></React.Fragment>;
                      }
                      return (
                        <a href="#/"
                          key={index}
                          className={`d-flex align-items-center comman-list with-after-50 py-2 m-0 dealing-list-item radius_3 tab-active`}
                        >
                          <div
                            className={`d-flex align-items-center align-items-center flex-grow-1 w100minus100per`}
                          >
                          <Form.Check type={providerData.type == "schedule" ? "radio" : "checkbox"} id={provider.userid}>
                            <div className="d-flex align-items-center">
                              <div
                                className={` d-flex align-items-center justify-content-center pe-3`}
                              >
                                <Form.Check.Input
                                  className="h20w20"
                                  name="provider"
                                  disabled={providerData.id > 0 && benchmarkDetail && benchmarkDetail.is_copy_from_schedule < 0}
                                  type={
                                    providerData.type == "schedule"
                                      ? "radio"
                                      : "checkbox"
                                  }
                                  checked={
                                    providerData.invited_providers.find(
                                      (x) =>
                                        x.email == provider.email &&
                                        x.staffid == provider.staffid
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    addEditInvitedProviders(
                                      provider.staffid,
                                      provider.email,
                                      e.target.checked
                                    );
                                  }}
                                />
                              </div>
                              <Form.Check.Label className="d-flex"> 
                              <div className="h25w25 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-1">
                                <div
                                  className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                  style={{
                                    backgroundImage: `url("${provider.profile_image_url}")`,
                                  }}
                                ></div>
                              </div>
                              <div
                                className="c-font color-white-80 f-14 ps-2 text-truncate title-fonts"
                              >
                                {provider.company}
                              </div>
                              </Form.Check.Label>
                            </div>
                          </Form.Check>
                          </div>
                          <div className="c-font color-white-60 f-14 text-truncate flex-grow-1 w100minus100per d-none">
                            {provider.email}
                          </div>
                        </a>
                      );
                    })}
                </div>
              </React.Fragment>
            ) : providerData.sources.includes("choose_from_past") &&
              providerData.id == 0 &&
              !providerList && providerList.length && !invitedProviders.length ? (
              <CommanPlaceholder
                mainSpacing="h-100 m-auto"
                imgType="no-user"
                placeholderText={`${_l("l_no")} ${_l("l_provider")}`}
              />
            ) : (
              <React.Fragment></React.Fragment>
            )}
            {providerData.id > 0 && benchmarkDetail && benchmarkDetail.is_copy_from_schedule < 0 &&
            providerData.invited_providers &&
            providerData.invited_providers.length ? (
              <React.Fragment>
                <div className="px-2 dealing-list">
                  <div className="bg-white-03-solid-imp c-font color-white-60 comman-white-box f-12 position-sticky py-2 title-fonts top-0 z-index-2">
                    {_l("l_selected")} {_l("l_provider")}
                  </div>
                  {providerData.invited_providers.map((provider, index) => {
                    return (
                      <a
                        key={index}
                        className={`d-flex align-items-center comman-list with-after-50 py-2 m-0 dealing-list-item radius_3 tab-active`}
                      >
                        <div
                          className={`d-flex align-items-center align-items-center flex-grow-1 w100minus100per`}
                        >
                          <div className="d-flex align-items-center">
                            <div
                              className={` d-flex align-items-center justify-content-center pe-3`}
                            >
                              <Form.Check
                                className="h20w20"
                                name="provider"
                                disabled={providerData.id > 0 && benchmarkDetail && benchmarkDetail.is_copy_from_schedule < 0}
                                type={
                                  providerData.type == "schedule"
                                    ? "radio"
                                    : "checkbox"
                                }
                                checked={
                                  providerData.invited_providers.find(
                                    (x) =>
                                      x.email == provider.email &&
                                      x.staffid == provider.staffid
                                  )
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            <div className="h25w25 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-1">
                              <div
                                className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                style={{
                                  backgroundImage: `url("${provider.profile_url}")`,
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="c-font color-white-80 f-14 ps-2 text-truncate title-fonts">
                            {provider.company}
                          </div>
                        </div>
                        <div className="c-font color-white-60 f-14 d-none text-truncate flex-grow-1 w100minus100per">
                          {provider.email}
                        </div>
                      </a>
                    );
                  })}
                </div>
              </React.Fragment>
            ) : providerData.id > 0 &&
              providerData.invited_providers &&
              !providerData.invited_providers.length ? (
              <CommanPlaceholder
                imgType="no-user"
                placeholderText={`${_l("l_no")} ${_l("l_provider")}`}
              />
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </div>
        </Col>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {bulkUploads ? (
        <ImportProviders
          handleClose={() => {
            setBulkUploads(false);
          }}
          handleImport={handleCsvImport}
          importFile={csvFile}
          setImportFile={(e) =>
            csvOnLoadHandler(e, setCsvFile, setProcessedCSV)
          }
          show={bulkUploads}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </Row>
  );
};

export default SelectProvider;
